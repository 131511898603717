*{
    font-family: 'Open Sans', sans-serif;
}
html, body{
    min-width: 100%;
    min-height: 100%;
}
ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a{
    color: inherit;
    text-decoration: none;
}

button{
    color: inherit;
    border: none;
    cursor: pointer;
}

button:focus{
    outline: none;
}

select:focus, input:focus, textarea:focus{
    outline: none;
}