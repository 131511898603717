.CabinetCard{
    display: grid;
    grid-template-columns: 1fr 2fr;
    background-color: white;
    border-radius: 4px;
    padding: 15px 15px 50px;
    position: relative;
    height: 255px;
    box-sizing: border-box;
    margin-bottom: 30px;
}

.CabinetCard img{
    width: 100%;
    height: 190px;
    object-fit: cover;
    border-radius: 4px;
}

.topBox, .bottomBox{
    display: flex;
    flex-grow:1;
}

.topBox div, .bottomBox div{
    flex-basis: 50%;
    flex-grow:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
}


.status{
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.rightContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
}

.topBox{
    border-bottom: 1px dashed #DADBDC;
}

.label{
    color: #292527;
    opacity: 0.7;
    font-size: 13px;
}

.cardInfo{
    color: #292527;
    font-weight: 600;
    font-size: 15px;
}

.lotName a{
    text-decoration: underline;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer;
}

.green{
    color: #057E36;
}