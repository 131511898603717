.fileUpload{
    margin-top: 20px;
    display: flex;
    border: 1px solid #057E36;
    border-radius: 4px;
    color: #057E36;
    align-items: center;
    cursor: pointer;
}

.fileUpload p{
    flex-grow: 1;
    text-align: center;
    font-weight: 600;
}

.fileUploadPlus{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:2px 13px;
    font-size: 30px;
    color: #057E36;
    border-right: 1px solid #057E36;
}

.fileUploadDescription{
    margin-top: 10px;
    color: #292527;
    opacity: 70%;
    font-size: 13px;
}

.uploadedFile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    margin-top: 20px;
    border-bottom: 1px solid #DADBDC;
    padding-bottom: 15px;
}

.uploadedFile img{
    width: 25px;
    margin-right: 15px;
}

.uploadedFileTitle{
    display: flex;
    align-items: center;
}

.uploadedFile i{
    justify-self: flex-end;
    cursor: pointer;
    font-size: 20px;
    color: #DADBDC;
}