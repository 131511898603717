.Cabinet{
    font-family: "Open Sans";
}

.topBar{
    background-color: white;
    padding-top:36px;
}
.title{
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
}
.list{
    padding: 0 10%;
    display: flex;
    max-width: 100%;

}

.active div{
    background-color: #f2f2f2;
    border-radius: 8px 8px 0 0;
    color:#103989;
}

.active .count{
    background-color:#abb3d0;
    border-radius: 8px;
    color:white;
}
.content{
    padding: 0 10%;
}
.listItem{
    display: flex;
    align-items: center;
    color: #6b6e74;
    white-space: nowrap;
    padding: 15px 40px;
}
.count{
    margin-left: 5px;
    font-size: 12px;
    padding: 4px;
    background-color:#abb3d0;
    color:white;
    border-radius: 11px;
}

.profileContent{
    width: 80%;
    display: flex;
    margin: 50px auto;
    background-color: white;
    padding: 30px 40px;
    box-sizing: border-box;
    border-radius: 8px;
}

.profileContent input{
    box-sizing: border-box;
    width: 60%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    padding: 9px 12px 7px 12px;
    font-size: 16px;
    line-height: 1.5;
}

.profileContent label{
    font-size: 14px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 10px;
}

.profileContent h3{
    font-size: 16px;
}

.saveButton{
    color: white;
    font-weight: bold;
    height: 40px;
    padding: 10px 19px;
    border-radius: 4px;
    background-color: #103989;
    margin-top: 30px;
}

.photoChangeBox{
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-direction: column;
    margin-right: 65px;
}

.photoChangeBox img{
    width: 140px;
    height: 140px;
    border-radius: 50%;
}

.photoChangeBox button{
    text-decoration-line: underline;
    color: #103989;
    background-color: white;
    font-size: 14px;
    margin-top: 15px;
}

.success{
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.colonItem{
    width: 100%;
}

.successContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: white;
    padding: 30px;
}

.successContent p{
    margin-bottom: 20px;
}

.successContent h2{
    margin-bottom: 20px;
}

.successContent div div{
    background-color: #f2f7fb;
}

.successContent label{
    font-size: 1em !important;
    font-weight: normal !important;
    line-height: normal !important;
    margin-top: 75px;
    height: 10px;
    margin-bottom: 20px;
    color: #6b6e74 !important;
    padding: 10px 18px 17px;
    border-radius: 20px;
    border: solid 1px #dadbdc;
}

.error{
    margin-top: 10px;
    color: #ea1a28;
}

.acceptBtn{
    width: 126px;
    height: 40px;
    padding: 9px 18.8px 10px 18px;
    border-radius: 20px;
    background-color: #103989;
    color: white;
    font-weight: bold;
}

.closeBtn{
    width: 106px;
    height: 40px;
    margin: 15px 20px 0 2px;
    padding: 9px 16px 10px;
    border-radius: 20px;
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
}


.dropBox{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    border: dashed 1px #556f99;
    background-color: #f2f7fb;
    margin-top: 10px;
    padding: 0 15%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dropBox h3{
    color: rgba(38, 39, 41, 0.56);
    text-align: center;
}

.dropBox button{
    height: 40px;
    width: 30%;
    color: #6b6e74;
    margin-top: 15px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
    margin: auto;
}

.dropBox input{
    display: none;
}

.passwordBox{
    display: flex;
    background-color: white;
    padding: 30px;
    border-radius: 4px;
    width: 80%;
    box-sizing: border-box;
    margin: 0 auto;
}

.passwordItem{
    display: flex;
    flex-direction: column;
}

.passwordItem input{
    box-sizing: border-box;
    width: 260px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    padding: 9px 12px 7px 12px;
    font-size: 16px;
    line-height: 1.5;
    margin-right: 30px;
}

.bottom{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.passwordItem label{
    font-size: 14px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 10px;
}

.colons{
    display: flex;
}


@media (max-width: 858px){
    .profileContent{
        width: 80%;
    }

    .profileContent input{
        width: 100%;
    }

}