.Restore{
    display: flex;
    justify-content: center;
}

.background{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top:0px;
    background-image: url('../../assets/img/bg.jpg');
    z-index: -3343;
    background-size: cover;
}
.background:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    z-index: 2;
}
.resForm{
    width: 30%;
    padding: 25px 60px 25px 40px;
    background-color: white;
    border-radius: 8px;
    margin-top: 90px;
}
.resForm input{
    height: 40px;
    margin-top: 15px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    padding: 8px 12px;
    font-size: 16px;
}

.resForm form{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.resForm form h1{
    color: #262729;
    font-size: 32px;
}

.butBox{
    display: flex;
    justify-content: flex-end;
}

.butBox button{
    margin-top: 15px;
    color: white;
    background-color:#103989;
    border-radius: 20px;
    height: 40px;
    width: 180px;
    font-size: 14px;
}

.regBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.regBox a{
    color: #103989;
    font-weight: bold;
    margin-left: 10px;
}
.regBox p{
    color: #6b6e75;
}

.resForm h3{
    font-weight: normal;
    margin-top: 10px;
}

.error{
    color: #ea1a28;
}

.success{
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.successContent{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    border-radius: 15px;
    background-color: white;
    padding: 20px;
}

.successContent button{
    background-color: #103989;
    color:white;
    border-radius: 20px;
    width: 40%;
    height: 40px;
    font-weight: bold;
    margin-top: 20px;
}

@media (max-width: 858px){
    .resForm{
        width: 60%;
        font-size: 0.8em;
        padding: 15px 40px 15px 40px;
    }
    .resForm form h1{
        font-size: 24px;
    }
    .resForm input{
        height: 20px;
    }

    .bottomBar{
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .bottomBar button{
        width: 130px;
        align-self: center;
        margin-bottom: 10px;
    }

    .butBox{
        justify-content: center;
    }
}