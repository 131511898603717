.ApplicationItem{
    position: relative;
    padding: 0 20px;
}

.backButton{
    color: #6B6E74;
    position: absolute;
    cursor: pointer;
    left: 20px;
}

.backButton i{
    margin-right: 10px;
}

.cadastNum{
    display: flex;
    justify-content: center;
    font-weight: 600;
    text-decoration: underline;
    color: #103989;
    margin-bottom: 10px;
}

.stageItem{
    padding: 20px 0;
    background-image: linear-gradient(to right, white 50%, #DADBDC 00%);
    background-position: bottom;
    background-size: 15px 1px;
    background-repeat: repeat-x;
}

.stageItemTitle{
    display: flex;
    position: relative;
    margin-bottom: 20px;
}

.stageItemTitle h4{
    font-weight: normal;
    color: #292527;
}

.stageItemTitle img{
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.stageItemTitle p{
    font-size: 12px;
    position: absolute;
    left: 40px;
    bottom: -20px;
    color: #6B6E74;
}

.fileSign{
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.landSign{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    align-items: center;
}

.landSign button{
    width: 40%;
    height: 45px;
    background-color: #103989;
    color: white;
    font-weight: 600;
    border-radius: 4px;
}

.landSignFile{
    width: 40%;
    display: flex;
    border-bottom: 1px solid #DADBDC;
    padding-bottom: 10px;
}

.landSignFile img{
    width: 25px;
    margin-right: 15px;
}

.landSignFile a{
    color: #103989;
    text-decoration: underline;
    font-weight: 500;
}

.withdrawButton{
    background-color: white !important;
    border: 1px solid #103989 !important;
    color: #103989 !important;
    font-weight: 500 !important;
}

.waitingText{
    margin-top: 30px;
}

.finishBtn{
    width: 25%;
    height: 45px;
    background-color: #103989;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    margin-top: 20px;
}

.signedButton{
    border: 1px solid #DADBDC !important;
    color: #DADBDC !important;
    background-color: white !important;
}

.recallBtn{
    border: 1px solid #DADBDC !important;
    color: #DADBDC !important;
    background-color: white !important;
    width: 40%;
    height: 45px;
    margin-top: 20px;
}

.acceptedButtons{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cancelBtn{
    border: 1px solid #6B6E74 !important;
    color: #6B6E74 !important;
    background-color: white !important;
    width: 40%;
    height: 45px;
    font-weight: bold;
    border-radius: 4px;
    margin-top: 20px;
}

.editBtn{
    border: 1px solid #103989 !important;
    color: #103989 !important;
    background-color: white !important;
    width: 40%;
    height: 45px;
    font-weight: bold;
    border-radius: 4px;
    margin-top: 20px;
}

.canceledText{
    text-align: center;
    margin-top: 30px;
    color: #ea1a2e;
    font-size: 20px;
    margin-bottom: 20px;
}