
.CardComponent{
    background: white;
    padding: 30px;
    border-radius: 4px;
    margin-bottom: 30px;
}

.backButton{
    color: #6B6E74;
    font-weight: 600;
    cursor: pointer;
}

.backButton i{
    margin-right: 10px;
}

.cardTop{
    color: #6B6E74;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.cardContent img{
    width: 50%;
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
}

.cardContent{
    display: flex;
}

.cardContentText{
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}

.viewButton{
    background-color: #103989;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    height: 40px;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.cancelButton{
    height: 40px;
    color: #979797;
    font-weight: 600;
    border: 1px solid #979797;
    width: 35%;
    background: white;
    border-radius: 4px;
    margin-left: 10px;
}

.cardContentBottom, .cardContentTop{
    flex-grow: 1;
    flex-basis: 50%;
}

.cardContentBottom{
    display: flex;
    align-items: flex-end;
}

.cardContentTop{
    border-bottom: 1px dashed #DADBDC;
}

.cardContentTop p{
    margin-top: 10px;
    font-size: 14px;
    color: #6B6E74;
    margin-bottom: 5px;
}

.cardContentTop a{
    text-decoration: underline;
    font-size: 17px;
    font-weight: 700;
}

.DocumentsComponent{
    background-color: white;
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 30px;
}

.tableDate, .tableNumber, .tableName, .tableStatus{
    text-align: left;
    font-weight: 400;
    color: #6B6E74;
    font-size: 14px;
    padding-right: 10px;
    padding-top: 10px;
}

.tableDate{
    width: 20%;
}

.tableNumber{
    width: 15%;
}

.tableName{
    width: 45%;
}

.tableStatus{
    width: 20%;
}

.documentItem table td{
    color: #292527;
    padding-right: 10px;
    border-bottom: 1px dashed #ECEDED;
    padding-bottom: 30px;
    padding-top: 5px;
}

.title{
    text-transform: uppercase;
    color: #292527;
    font-weight: 600;
}

.subtitle{
    text-transform: uppercase;
    color: #292527;
    font-weight: 600;
    font-size: 14px;
    opacity: 0.5;
    margin-bottom: 30px;
}

.document{
    display: flex;
    align-items: center;
    color: #103989;
    font-weight: 600;
    font-size: 16px;
    margin-top: 5px;
}

.document i{
    margin-right: 10px;
    font-size: 18px;
}

.actionItem{
    border: 1px solid #103989;
    border-radius: 4px;
    height: 60px;
    display: flex;
    padding: 0 30px;
    align-items: center;
    color: #103989;
    font-weight: 600;
    font-size: 16px;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
}

.actionItemDisabled{
    border: 1px solid #6B6E74;
    border-radius: 4px;
    height: 60px;
    display: flex;
    padding: 0 30px;
    align-items: center;
    color: #6B6E74;
    font-weight: 600;
    font-size: 16px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.circle{
    border: 1px solid #103989;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-sizing: border-box;
}

.actionItemDisabled .circle{
    border: 1px solid #6b6e75;
}

.actionItem p{
    flex-basis: 90%;
}

.actionItem i{
    color: #292527;
    font-size: 25px;
}

.actionItemDisabled p{
    flex-basis: 90%;
}

.actionItemDisabled i{
    color: #292527;
    font-size: 25px;
}


.actionsTitle{
    color: #292527;
    text-transform: uppercase;
    margin: 20px 0;
    font-size: 15px;
}

.ContractComponent{
    background-color: white;
    border-radius: 4px;
    padding: 30px;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.buttonsTop{
    margin-top: 30px;
    display: flex;
    text-align: center;
    margin-bottom: 20px;
}

.buttonsTop button{
    height: 60px;
    border: 1px solid #103989;
    border-radius: 4px;
    color: #103989;
    font-weight: bold;
    background-color: white;
    flex-basis: 45%;
    font-size: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonsTop button i, .buttonsTop button img{
    font-size: 18px;
    margin-right: 17px;
}

.buttonsTop p{
    flex-basis: 10%;
    align-self: center;
    font-weight: bold;
    color: #103989;
}

.bottomButton{
    background-color: #103989;
    border-radius: 4px;
    width: 100%;
    color: white;
    font-weight: bold;
    height: 60px;
    font-size: 15px;
    margin-bottom: 20px;
}

.PaymentComponent{
    padding: 30px;
    background-color: white;
    border-radius: 4px;
}

.paymentStatus{
    color: #6B6E74;
    font-size: 14px;
}

.paymentStatus{
    color: #292527;
}

.protocolCheckModal{
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.protocolCheckModal a{
    color: #103989;
    font-weight: bold;
    text-align: center;
    display: block;
    margin: 10px 0;
    font-size: 16px;
}

.protocolCheckModal button{
    background-color: #103989;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    width: 40%;
    height: 40px;
    margin-top: 15px;
    align-self: center;
}

.uploadContractModal{
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadContractModal input{
    display: none;
}

.uploadContractModal p{
    font-weight: bold;
    color: #103989;
}

.selectFileButton{
    background-color: white;
    border-radius: 4px;
    border: 1px solid #103989;
    color: #103989;
    font-weight: bold;
    height: 40px;
    width: 60%;
    margin: 15px 0;
}

.uploadFileButton{
    background-color: #103989;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    height: 40px;
    width: 60%;
    margin-top: 15px;
}
