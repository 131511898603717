.Lot{
    margin-top: 60px;
    display: flex;
    position: relative;
}

.left, .middle, .right{
    background-color: white;
    width: 80%;
    padding: 0px 30px 50px 30px;
}


.right{
    position: fixed;
    width: 30%;
    right: 0;
    box-sizing: border-box;
    height: calc(100vh - 140px);
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.middle{
    width: 30%;
    margin: auto;
    height: calc(100vh - 190px);
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-color: #f2f2f2 white;
    scrollbar-width: thin;
}

.middle::-webkit-scrollbar{
    width: 7px;
    background-color: white;
}

.middle::-webkit-scrollbar-thumb{
    width: 12px;
    background-color: #f2f2f2;
    border-radius: 15px;
    border: 1px solid #f2f2f2;
    border-right: 2px solid white;
}

.left::-webkit-scrollbar, .right::-webkit-scrollbar {
    display: none;
}

.left{
    margin-right: 30px;
    height: calc(100vh - 140px);
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.middleTitle{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 50px;
}

.bets{
    font-size: 14px;
    margin-top: 15px;
}

.betItem{
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #F2F2F2;
}

.betTime{
    width:35%
}

.betAmount{
    width: 25%;
    text-align: right;
    font-weight: 700;
}

.betOwner{
    width: 25%;
}

.betCheckmark{
    width: 5%;
}

.auctionStage{
    display: flex;
    position: relative;
    margin-bottom: 40px;
    font-size: 14px;
}

.auctionStage img{
    margin-right: 20px;
    background-color: white;
    width: 20px;
    height: 20px;
}

.auctionStageTime{
    position: absolute;
    bottom: -22px;
    left: 40px
}

.left h2, .right h2{
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 16px;
    text-transform: uppercase;
    color: #292527;
}

.leftBorder{
    width: 1px;
    height: 228px;
    background-color: #97B6A4;
    position: absolute;
    top:60px;
    left:10px;
}

.left{
    padding-left: 12%;
    box-sizing: border-box;
    position: fixed;
    width: 30%;
    height: 100vh;
}

.timer{
    font-size: 12px;
    color: #6B6E74;
    padding: 40px 30px;
    width: 60%;
    box-sizing: border-box;
}

.highestBet{
    padding: 10px 30px;
    background: #F2F2F2;
    border-radius: 8px;
    width: 60%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.highestBet h3{
    color: #057E36;
}

.statusItem{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #292527;
    margin-bottom: 20px;
    width: 60%;
}

.statusItem img{
    margin-right: 20px;
}

.betAccepted{
    width: 60%;
    margin-bottom: 20px;
}

.betAcceptedContent{
    background-color: #F2F2F2;
    padding: 10px 15px;
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.betAcceptedContent h5{
    color: #057E36;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.betAcceptedContent p{
    color: #6B6E74;
    font-size: 14px;
}

.changeBetButton{
    background: #103989;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    padding: 10px 0;
    width: 100%;
    text-align: center;

}

.betCheckmark{
    width: 20px;
    height: 20px;
}

.spectatorBox{
    background: rgba(253, 126, 125, 0.25);
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 10px 20px;
    position: absolute;
    top:-50px;
    margin-left: 25px;
}

.spectatorBox img{
    margin-right: 10px;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% {  transform: rotate(359deg); }
}

.roundSpinner{
    animation: spin 2s linear infinite;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    object-fit: none;
    margin-right: 20px;
    background-color: white;
}


.roundSpinner+p{
    font-weight: bold;
}

.betModal{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;
}

.betModal input{
    border: 1px solid #DADBDC;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 12px;
    color: #6B6E74;
    width: 100%;
    margin-bottom: 30px;
}

.betModal p{
    color: #6B6E74;
    margin-bottom: 30px;
}

.betModal label{
    font-weight: bold;
    align-self: flex-start;
    margin-top: 30px;
    margin-bottom: 5px;
}

.betModalTitle{
    color: #262729;
    font-size: 32px;
    margin-bottom: 10px;
}

.betModalTimer{
    width: 40%;
}

.betModalTimer p{
    font-size: 10px;
    margin-bottom: 0;
}

.betModalButtons{
    display: flex;
    width: 100%;
}

.betModalRejectBut{
    background: rgba(255, 255, 255, 0.0001);
    border: 1px solid #DADBDC;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 30px;
    margin-right: 10px;
    flex-grow: 1;
}

.betModalBetBut{
    background: #103989;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    padding: 10px 30px;
    flex-grow: 1;
}

.modalError{
    color: red !important;
    font-size: 20px;
    margin-bottom: 0px !important;
    margin-top: 10px;
}

.preferenceModal{
    padding: 40px;
}

.preferenceModal h1{
    margin: 20px 0;
    text-align: center;
}


.preferenceTimer{
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
}
