.Modal{
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-size: 14px;
    backdrop-filter: blur(5px);
}

.ModalTransparent{
    background: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-size: 14px;
    backdrop-filter: blur(5px);
}

.ModalContent{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    position: relative;
}

.close{
    position: absolute;
    color: black;
    font-size: 25px;
    right: 25px;
    top: 25px;
    cursor: pointer;
}