
.dropBox{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    border: dashed 1px #556f99;
    background-color: #f2f7fb;
    margin-top: 10px;
    padding: 0 15%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dropBox h3{
    color: rgba(38, 39, 41, 0.56);
    text-align: center;
}

.dropBox button{
    height: 40px;
    width: 30%;
    color: #6b6e74;
    margin-top: 15px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
    margin: auto;
}

.dropBox input{
    display: none;
}

.photos{
    margin:20px 0;
}

.documentsItems, .photosItems{
    display: flex;
    flex-wrap: wrap;
}
