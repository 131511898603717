.leaflet-popup-content-wrapper{
    border-radius: 0px;
    padding: 0;
}

.leaflet-popup-content{
    display: flex;
    margin: 0;
}

.leaflet-popup-content p{
    margin: 0;
}

.popup-button{
    text-align: center;
    color: white !important;
    padding: 8px 11px;
    border-radius: 16px;
    background-color: #103989;
    margin-top: 15px;
    cursor: pointer;
}

.leaflet-popup-content-wrapper img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0;
}

.leaflet-popup-content div{
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.leaflet-popup-content a p{
    text-decoration: underline;
    color: #292527;
}

.leaflet-popup-content a i{
    font-size: 12px;
    margin-left: 5px;
    color: #103989;
}
.marker-cluster{
    background-color: #103989;
}

.marker-cluster-small div {
    background-color: #103989;
    color: white;
    font-weight: bold;
}