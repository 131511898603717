.countdown{
    color: #057E36;
    font-size: 1.4vw;
}
.TimerBox{
    position: relative;
}

.TimerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.TimerContent p{
    font-size: 0.8vw;
    white-space: nowrap;
    text-align: center;
}