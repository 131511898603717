.Participation{
    padding: 50px 10%;
}

.Participation h1{
    margin-bottom: 30px;
    font-size: 30px;
    color: #292527;
    text-transform: uppercase;
}

.content{
    background-color: white;
    padding: 30px;
}

.contentTitle{
    font-size: 15px;
    color: #292527;
    margin-bottom: 10px;
}

.contentSubtitle{
    font-size: 15px;
    color: #292527;
    width: 60%;
}

.stages{
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
}

.stages p{
    border: 2px solid #daece1;
    color: #daece1;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 8px;
}

.line{
    width: 100%;
    height: 1px;
    background-color: #daece1;
    margin-top: 13px;
}

.completedLine{
    width: 100%;
    height: 1px;
    background-color: #82BE9A;
    margin-top: 13px;
}
/*.stages p::after{*/
/*    content: "";*/
/*    width: calc((80vw - 30px) / 7 - 12px);*/
/*    height: 1px;*/
/*    border-bottom: 1px solid red;*/
/*    position: absolute;*/
/*    left: 27px;*/
/*}*/

.stages .activeStage{
    border: 2px solid #82BE9A;
    color: #82BE9A;
}

.stages .completedStage{
    width: 29px;
    height: 29px;
}

.inputItem{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.inputItem input{
    min-width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: solid 1px #dadbdc;
    background-color: inherit;
    font-size: 16px;
    padding: 8px 16px;
    color: #6b6e74;
    padding-right: 30px;
}

.inputItem label{
    color: #292527;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 20px;
}
.inputItem textarea{
    height: 100px;
    min-width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(218, 219, 220);
    background-color: inherit;
    font-size: 16px;
    padding: 8px 30px 8px 16px;
    resize: none;
    color: rgb(107, 110, 116);
}

.nextStageButton{
    margin-top: 20px;
    border: 1px solid #103989;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
    padding: 9px 90px;
    color: #103989;
    font-weight: 600;
}

.rnokpp{
    display: flex;
    align-items: flex-end;
}

.checkboxItem{
    width: 70%;
    padding-left: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
}

.checkboxItem input{
    margin-top: 5px;
    margin-right: 5px;
}



.itemRow{
    display: flex;
}

.itemRow .inputItem{
    margin-right: 40px;
}

.gap{
    margin:50px 0;
}

.fileSign{
    width: 30%;
    margin-right: 20px;
}

.checkboxRow{
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.checkboxRow .checkboxItem{
    align-items: center;
    padding-left: 0;
    margin-right: 40px;
}

.fileUploadRow .fileUpload{
    width: 60%;
}
.fileUploadRow .fileUploadDescription{
    width: 60%;
}
.finishButton{
    color: white;
    font-weight: 600;
    background-color: #103989;
    border-radius: 4px;
    padding: 10px 50px;
    margin-top: 20px;
}

.Finish{
    padding: 100px 25%;
}

.finishContent{
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    background-color: white;
    padding: 70px;
}

.finishContent h2{
    color: #292527;
    text-transform: uppercase;
}

.grey{
    color: #6B6E74;
    font-size: 14px;
    text-align: center;
}

.invoiceButton{
    color: #057E36;
    border-radius: 4px;
    border: 1px solid #057E36;
    width: 60%;
    text-align: center;
    padding: 10px 30px;
    margin-top: 10px;
    font-weight: 600;
    box-sizing: border-box;
    cursor:pointer;
}

.invoiceButton:first-child{
    margin-top: 40px;
}

.subHeader{
    text-transform: uppercase;
}

.error{
    font-weight: 600;
    margin-top: 20px;
    color: #ea1a28;
}