.topBar{
    background-color: white;
    padding-top:36px;
}

.topBar h1{
    padding:0px 10%;
    font-size: 38px;
}

.topBar p{
    margin-bottom: 20px;
    padding:0px 10%;
}

.title{
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
}
.list{
    padding: 0 10%;
    display: flex;
    max-width: 100%;

}

.active div{
    background-color: #f2f2f2;
    border-radius: 8px 8px 0 0;
    color:#103989;
}
.listItem{
    display: flex;
    padding: 15px;
    align-items: center;
    color: #6b6e74;
    white-space: nowrap;
}

.content{
    padding: 40px 10%;
}

.filterBox{
    display: flex;
    background: #f2f2f2;
}

.filterItem{
    display: flex;
    flex-direction: column;
}

