.Applications{
    padding: 30px 7%;
}

.content{
    background-color: white;
    padding: 20px 10px;
    border-radius: 4px;
}

.table{
    width: 100%;
}

.table th{
    text-align: left;
    font-size: 14px;
    color: #6B6E74;
    font-weight: normal;
    padding-bottom: 10px;
}

.table th:first-child{
    padding-left: 20px;
}


.table img{
    width: 20px;
}

.tableItem{
    padding: 20px;
    cursor: pointer;
}

.tableItem td{
    border: 1px solid #ECEDED;
    border-right: 0px;
    border-left: 0px;
}

.tableItem td:first-child{
    padding: 20px;
    padding-right: 0px;
    border-left: 1px solid #ECEDED;
    border-radius: 4px 0 0 4px;
}

.tableItem td:last-child{
    padding-left: 0px;
    border-right: 1px solid #ECEDED;
    border-radius: 0 4px 4px 0;
}

.tableGap td{
    padding: 5px;
}

.tableItem:hover .tableItem td{
    font-size: 30px;
}

.green{
    color: #057E36;
}

.yellow{
    color: #FFAA00;
}

.red{
    color: #ea1a28;
}

.warning{
    text-align: center;
    color: #6B6E74;
    margin-top: 30px;
    margin-bottom: 10px;
}