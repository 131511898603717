.Registration{
    padding: 50px 10%;
}

.Registration h1{
    margin-bottom: 30px;
    font-size: 30px;
    color: #292527;
}

.content{
    background-color: white;
    padding: 30px;
}

.contentTitle{
    font-size: 15px;
    color: #292527;
    margin-bottom: 10px;
}

.contentSubtitle{
    font-size: 15px;
    color: #292527;
    width: 60%;
}
.stages{
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
}

.stages p{
    border: 2px solid #daece1;
    color: #daece1;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 8px;
}

.line{
    width: 100%;
    height: 1px;
    background-color: #daece1;
    margin-top: 13px;
}

.completedLine{
    width: 100%;
    height: 1px;
    background-color: #82BE9A;
    margin-top: 13px;
}
/*.stages p::after{*/
/*    content: "";*/
/*    width: calc((80vw - 30px) / 7 - 12px);*/
/*    height: 1px;*/
/*    border-bottom: 1px solid red;*/
/*    position: absolute;*/
/*    left: 27px;*/
/*}*/

.stages .activeStage{
    border: 2px solid #82BE9A;
    color: #82BE9A;
}

.stages .completedStage{
    width: 29px;
    height: 29px;
}


.inputItem{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;
}

.inputItem input{
    min-width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: solid 1px #dadbdc;
    background-color: inherit;
    font-size: 16px;
    padding: 8px 16px;
    color: #6b6e74;
    padding-right: 30px;

}

.inputItem label{
    color: #292527;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 20px;
}
.inputItem textarea{
    height: 100px;
    min-width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(218, 219, 220);
    background-color: inherit;
    font-size: 16px;
    padding: 8px 30px 8px 16px;
    resize: none;
    color: rgb(107, 110, 116);
}

.short{
    width: calc(100% / 3 + 40px);
}

.registerButton{
    width: calc(100%/3);
    height: 40px;
    background-color: #103989;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    margin-top: 40px;
}

.registerButton:disabled{
    background-color: #AFC7F6;
}

.alreadyRegistedText{
    margin-top: 20px;
    color: #6B6E75;
}

.alreadyRegistedText a{
    margin-left: 10px;
    color: #103989;
}

.backButton{
    color: #6B6E74;
    font-weight: 600;
    cursor: pointer;
}

.backButton i{
    margin-right: 10px;
}

.confirmationBox{
    margin: 30px 0;
    background-color: #F2F2F2;
    padding: 30px;
    width: 70%;
}

.subtitle{
    font-family: "Open Sans";
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 20px;
}

.confirmationContent{
    display: flex;
}

.blueText{
    color: #103989;
    font-weight: 500;
}

.grayText{
    color: #6B6E74;
    font-weight: 400;
}

.confirmationContent img{
    margin-right: 20px;
}

.codeInput{
    display: flex;
    flex-direction: column;
}

.codeInput label{
    color: #292527;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 20px;
}

.codeInput input{
    font-size: 24px;
    padding: 5px;
    width: 100px;
    text-align: center;
    color: #6B6E74;
    border: 1px solid #DADBDC;
}

.resendCodeBtn{
    color: #103989;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
    margin:20px 0 80px;
}

.error{
    color: #ea1a28;
    font-size: 14px;
}


.itemRow{
    display: flex;
}

.checkboxItem{
    width: 70%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
}

.checkboxItem input{
    margin-top: 5px;
    margin-right: 5px;
}

.successModal{
    padding: 30px;
    text-align: center;
}

.successModal button{
    background-color: #103989;
    color: white;
    border-radius: 4px;
    font-weight: 600;
    height: 40px;
    padding: 0 20px;
    margin-top: 20px;
}
