body{
    background-color: #f2f2f2;
}
.topBar{
    background-color: white;
    padding-top:36px;
}
.title{
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
}
.list{
    padding: 0 10%;
    display: flex;
    max-width: 100%;

}

.active div{
    background-color: #f2f2f2;
    border-radius: 8px 8px 0 0;
    color:#103989;
}

.active .count{
    background-color:#abb3d0;
    border-radius: 8px;
    color:white;
}
.content{
    padding: 0 10%;
}
.listItem{
    display: flex;
    padding: 12px 36px;
    align-items: center;
    color: #6b6e74;
    white-space: nowrap;
}
.count{
    margin-left: 5px;
    font-size: 12px;
    padding: 4px;
    background-color:#abb3d0;
    color:white;
    border-radius: 11px;
}

.filter{
    padding:0 10%;
}

.filterBottom{
    display: flex;
    justify-content: space-between;
    color:#6b6e74;
    padding-bottom: 20px;
}
.filterBottomLeft{
    display: flex;
}
.resetBtn{
    border: 1px solid #dadbdc;
    font-size: 14px;
    padding: 7px 16px;
    border-radius: 20px;
}
.resetBtn:focus{
    border: 1px solid #dadbdc;
}
.filterBox{
    font-size: 16px;
    background-color: #f2f2f2;
    color: #6b6e74;
    display: flex;
    margin-left: 2px;
    align-items: center;
    padding: 8px 12px;
    border: solid 1px #dadbdc;
    width: 20%;
    box-sizing: border-box;
    margin-right: 5px;
    border-radius: 4px;
}

.filterBox p{
    margin-right: 5px;
}
.filterInput{
    position: relative;
    width: 40%;
    margin-right: 5px;
}
.filterInput input{
    box-sizing: border-box;
    font-size: 16px;
    background-color: #f2f2f2;
    color: #6b6e74;
    display: flex;
    align-items: center;
    padding: 8px 10px 8px 35px;
    width: 100%;
    border: solid 1px #dadbdc;
    border-radius: 4px;
}

.filterInput i{
    position: absolute;
    z-index: 324;
    bottom: 9px;
    left: 5px;
    font-size: 24px;
    color:#6b6e74;

}

.topSearchBar{
    width: 100%;
    height: 310px;
    position: relative;
    overflow: hidden;
}

.backgroundImage{
    position: absolute;
    width: 100%;
    top: -210px;
    object-fit: cover;

}

.topFiltersBoxBackground{
    width: 53%;
    height: 73%;
    opacity: 0.6;
    background-color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.topFiltersBox{
    color: white;
    box-sizing: border-box;
    padding: 25px 65px;
    width: 53%;
    height: 73%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.filterTypes{
    display: flex;
    margin-top: 20px;
    margin-right: -5px;
}

.filterTypes p{
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    flex-grow: 1;
    padding: 6px 16px 8px 16px;
    border: solid 1px #dadbdc;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
}

.filterCategories{
    display: flex;
}

.filterCategories p{
    text-align: center;
    font-size: 16px;
    flex-grow: 1;
    padding: 4px 16px 6px 16px;
    border: solid 1px #dadbdc;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
}

.filterTypeAcive{
    background-color: #dadbdc;
    color: #292527;
}

.filterInputs {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.sliderFilter input{
    font-size: 16px;
    width: 100px;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
}

.sliderInputs{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.sliderLine {
    background-color:#6b6e74;
    opacity: 60%;
    height: 2px;
    margin: 0 5px;
    width: 15px;
}

.filterBox{
    position: relative;
}

.filterBoxContent{
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: space-between;
    width: 100%;
}

.filterDropdown{
    z-index: 10;
    padding: 12px 15px 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    background-color: white;
    top: 42px;
    left: -50%;
}

.filterSearchButton{
    background-color: #057e36;
    color: white;
    height: 42px;
    width: 20%;
    margin-left: 2px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
}

.mapFilter p{
    display: inline;
    margin-left: 10px;
}

.filterCategories{
    flex-wrap: wrap;
    margin-right: -5px;
}

.backgroundImage{
    position: absolute;
    width: 100%;
    object-fit: contain;
    top: 0;
}

@media (max-width: 1100px){
    .list{
        padding: 0px;
        overflow: scroll;
        overflow-y: hidden;
    }

    .filterInputs{
        flex-wrap: wrap;
    }

    .filterInput{
        width: 100%;
        margin-bottom: 1.75vw;
    }

    .topFiltersBox{
        width: 100%;
        padding: 35px 10%;
    }

    .topFiltersBoxBackground{
        width: 90%;
    }


    .backgroundImage{
        position:static;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .filterBox{
        margin: 0;
        width: 49%;
    }
    .areaFilter{
        margin-left: 2%;
    }

    .filterSearchButton{
        width: 100%;
        margin: 0;
        margin-top: 1.75vw;
    }

    .topSearchBar{
       height: 450px
    }

}

@media (max-width: 700px) {
    .topFiltersBox{
        width: 100%;
        padding: 35px 2vw;
    }
    .topFiltersBoxBackground{
        width: 100%;
        height: 85%;
    }
}

