.Card{
    overflow: hidden;
    height: 230px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px;
}

.mainImg{
    border-radius: 0 0 0 8px;
    width: 25%;
    min-width: 25%;
    object-fit: cover;
}

.itemDescription{
    display: block;
}

.cardContent{
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid #ededee;
}

.left{
    max-width: 500px;
    font-size: 14px;
    color: #292527;
}
.left h5{
    font-size: 14px;
    display: inline;
    margin-right: 5px;
}

.cadastNum{
    text-decoration: underline;
    color: #292527;
    font-weight: bold;
    display: block;
}

.cadastNum i{
    font-size: 12px;
    margin-left: 5px;
    color: #103989;
}

.right{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #292527;
}

.left img{
    display: inline-block;
}

.type, .location, .time{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.saveBtn{
    display: flex;
    align-items: center;
    color: #FFAA00;
}

.saveBtn p{
    margin-left: 5px;
}

.description{
    height: 38px;
    word-break: break-word;
    margin: 15px 0;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.startingPrice p{
    white-space: nowrap;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.startingPrice h4{
    white-space: nowrap;
    font-size: 18px;
    margin-bottom: 10px;
}

.cardContentWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 25px;
}

.bottomBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

.left h5{
    color:#292527;
    font-weight: normal;
}

.aimIcon{
    width: 20px;
    margin-right: 7px;
}
.locationIcon{
    width: 14px;
    margin-left: 3px;
    margin-right: 10px;
}

.type p{
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: normal;
}

.location{
    margin-bottom: 20px;
}

@media (max-width: 858px){

    .Card{
        font-size: 14px;
        flex-direction: column;
        height: fit-content;
        padding: 15px;
        box-sizing: border-box;
        position: relative;
    }

    .Card p{
        width: 100%;
        word-break: break-all;
        white-space: normal;
        word-break: break-all;
    }

    .startingPrice p{
        white-space: nowrap;
        margin-top: 20px;
    }

    .left h5{
        display: block;
    }

    .location p{
        white-space: normal;
    }

    .Card img{
        width: 100%;
    }

    .type, .location, .time{
        color: #292527;
        display: flex;
        opacity: 0.7;
    }

    .type img, .location img, .time img{
        width: 10px;
        display: inline;
    }

    .cardContent{
        flex-direction: column;
    }

    .saveBtn{
        position: absolute;
        top: 7%;
        color: white;
    }

    .descContent{
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .right{
        opacity: 0.7;
        color: #292527;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: space-between;
    }

    .right h4{
        opacity: 100;
        color: #000;
        font-size: 16px;
    }


}