.headerBox{
    background-color: white;
    display: flex;
    padding: 26px 10%;
    justify-content: space-between;
    align-items: center;
}

.headerBox h1{
    font-size: 38px;
}

.headerBox button{
    background-color: #103989;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 20px;
    height: 40px;
    border-radius: 20px;
}

.filtersBox{
    padding: 30px 10%;
    display: flex;
    width: 500px;
    justify-content: space-between;
}

.filtersBox input{
    margin-right: 15px !important;
}

.success{
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.colonItem{
    width: 100%;
}

.successContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    background-color: white;
    padding: 30px;
}

.dropBox{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    border: dashed 1px #556f99;
    background-color: #f2f7fb;
    margin-top: 10px;
    padding: 0 15%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dropBox h3{
    color: rgba(38, 39, 41, 0.56);
    text-align: center;
}

.dropBox button{
    height: 40px;
    width: 30%;
    color: #6b6e74;
    margin-top: 15px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
    margin: auto;
}

.dropBox input{
    display: none;
}

.acceptBtn{
    width: 126px;
    height: 40px;
    padding: 9px 18.8px 10px 18px;
    border-radius: 20px;
    background-color: #103989;
    color: white;
    font-weight: bold;
}

.closeBtn{
    width: 106px;
    height: 40px;
    margin: 15px 20px 0 2px;
    padding: 9px 16px 10px;
    border-radius: 20px;
    border: solid 1px #dadbdc;
    background-color: rgba(255, 255, 255, 0);
}

.buttonsBox{
    align-self: flex-start;
}

.monthDiv{
    padding: 30px;
    margin: 20px 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    background-color: white;
    flex-wrap: wrap;
    border-radius: 4px;
}

.monthDiv h1{
    margin-bottom: 25px;
}

.monthDiv h1:after{

}

.monthCards{
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(12% / -4);
}

.monthCard{
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #292527;
    word-break: break-all;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ECEDED;
    padding: 15px 0;
}

.cardContent{
    display: flex;
    align-items: center;
}

.monthCard img{
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    margin-right: 20px;
}

.date{
    color: #6B6E74;
    font-weight: normal;
    margin-bottom: 10px;
}

.cardDelete{
    color: #103989;
    cursor: pointer;
    text-decoration: underline;
}

.clearFilters{
    white-space: nowrap;
    padding: 5px 10px;
    height: 40px;
    border-radius: 20px;
    border: solid 1px #dadbdc;
}

.filtersBox p{
    margin-right: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.emptyInvoices{
    text-align: center;
    margin-top: 30px;
}

@media (max-width: 858px){
    .headerBox h1{
        font-size: 30px;
    }

    .headerBox{
        flex-wrap: wrap;
    }

    .headerBox button{
        background-color: #103989;
        color: white;
        font-weight: bold;
        font-size: 12px;
        padding: 2px 13px;
        height: 40px;
        border-radius: 20px;
        white-space: nowrap;
        margin-left: 5px;
        margin-top: 10px;
    }

    .filtersBox{
        width: 100%;
        box-sizing: border-box;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .monthCard{
        width: 44%;
        margin-right: calc(12% / 2);
    }

    .successContent{
        width: 80%;
    }
}