
.Login{
    display: flex;
    justify-content: center;
}

.background{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top:0px;
    background-image: url('../../assets/img/bg.jpg');
    z-index: -3343;
    background-size: cover;
}
.background:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    z-index: 2;
}
.logForm{
    width: 30%;
    padding: 25px 60px 25px 40px;
    background-color: white;
    border-radius: 8px;
    margin-top: 90px;
}
.logForm input{
    height: 40px;
    margin-top: 15px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    padding: 8px 12px;
    font-size: 16px;
}

.logForm form{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.logForm form h1{
    color: #262729;
    font-size: 32px;
}

.bottomBar{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bottomBar button{
    color: white;
    background-color:#103989;
    border-radius: 20px;
    height: 40px;
    width: 40%;
    font-size: 14px;
}
.bottomBar a{
    font-size: 14px;
    color: #103989;
    font-weight: bold;
    white-space: nowrap;
    margin-right: 5px;
}
.bottomText {
    text-align: center;  
    width: 100%;          
    margin: 0 auto;      
    overflow: hidden;    
}

.bottomText p {
    display: inline;
     color: #6b6e75;
     position: relative;
     padding: 0 25px;
     background-color:white;
     z-index: 222;
}

.bottomText p:before {
    display: block;                 
    width:1000px;                    
    content: '';                     
    position: absolute;              
    border-bottom: 1px solid #abb3d0;  
    top:50%;                         
    right: 60px;                   
}

.bottomText p:after {
    display: block;
    width:1000px;
    content: '';
    position: absolute;
    border-bottom: 1px solid #abb3d0;
    top:50%;
    left: 60px; 
}

.regBox{
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 30px;
}

.regBox a{
    color: #103989;
    font-weight: bold;
    margin-left: 10px;
}
.regBox p{
    color: #6b6e75;
}

.error{
    color: #d12c2c;

}

.googleButt{
    width: fit-content;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}
@media (max-width: 858px){
    .logForm{
        width: 80%;
        font-size: 0.8em;
        box-sizing: border-box;
        padding: 10px 40px;
    }
    .logForm h1{
        font-size: 20px;
    }

    .logForm input{
        height: 20px;
    }

    .logForm form{
        margin-bottom: 10px;
    }

    .bottomBar button{
        width: 40%;
        font-size: 13px;
    }

    .bottomBar a{
        font-size: 12px;
    }

    .regBox{
        flex-direction: column;
    }

    .logForm h1{
        font-size: 18px;
    }

    .logForm form h1{
        font-size: 24px;
    }

    .gmailReg{
        font-size: 15px;
        text-align: center;
    }
    
}