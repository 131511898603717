.content{
    width: 80%;
    margin: auto;
}

.topText{
    text-align: center;
    margin: 35px 0 25px 0;
}

.topText p{
    font-size: 12px;
}

.topText h3{
    font-weight: normal;
    font-size: 14px;
}

.leftCol{
    width: 45%;
    position: relative;
}


.leftArr{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    position: absolute;
    z-index: 99;
    top:40%;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#92959a;
    cursor: pointer;
}

.rightArr{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    position: absolute;
    z-index: 99;
    top:40%;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#92959a;
    cursor: pointer;
}

.lotContent{
    display: flex;
    justify-content: space-between;
}

.leftCol img{

    border-radius: 8px;
}
.Lot ::-webkit-scrollbar {
    background-color: inherit;
}

.Lot ::-webkit-scrollbar-thumb {
    background: #8f9297;
    border-radius:5px;
}
.otherPhotos{
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 10px;
}

.otherPhotos img{
    max-height: calc(100% - 15px);
    align-self: top;
    margin-right: 10px;
    cursor: pointer;
}

.rightCol{
    width: 50%;
    font-size: 12px;
}

.rightCol h2{
    font-size: 16px;
}

.cadastNum{
    text-decoration: underline;
    color: #292527;
}

.cadastNum i{
    font-size: 12px;
    margin-left: 5px;
    color: #103989;
}

.areaText{
    margin: 5px 0;
}

.placeBoxButtons{
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: red;
    padding: 10px 35px;
}

.makeBetButton{
    height: 40px;
    padding: 9px 15px 10px;
    background-color: #103989;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius:20px ;
    white-space: nowrap;
    margin-left: 50%;
    transform: translate(-50%);
    margin-top: 23px;
}

.priceBox{
    padding: 15px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    min-width: fit-content;
}

.priceBoxButtons{
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 10px 35px;
}

.priceBoxButtons h3{
    font-size: 22px;
    color: #103989;

}

.priceBoxButtons button{
    height: 40px;
    padding: 9px 15px 10px;
    background-color: #103989;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius:20px ;
    white-space: nowrap;
}

.priceBoxButtons button:disabled,
.priceBoxButtons button[disabled]{
    background-color: #6b6e74;
}
.priceBoxButtons a{
    color: #103989;
    font-weight: bold;
    font-size: 14px;
}

.yourBetText{
    margin-top: 10px;
    font-size: 14px;
}

.infoBox{
    display: flex;
    justify-content: space-between;
}

.infoBox img{
    width: 10px;
    margin-right: 5px;
}

.lotOwner{
    display: flex;
    margin-top: 15px;
    word-break: normal;
    min-width: fit-content;
}

.lotOwner img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.type{
    margin-top: 15px;
}
.type, .location, .time{
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.decriptionBox{
    margin: 20px 0;
    font-size: 14px;
}

.memberText{
    color: #103989;
    font-size: 16px;
}

.bidEndCountdown{
    color: #057e36;
    text-align: center;
    font-size: 22px;
}

.betsTitle{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.betsList{
    margin-top: 10px;
    padding: 0px 10px;
    border-radius: 8px;
    background-color: #ffffff;
}

.bet{
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid red;;
}

.betOwner, .betTime, .betAmount{
    width: 33%;
}

.betAmount{
    text-align: center;
}

.betOwner{
    padding-left: 15px;
}

.yourBet{
    margin-left: 20px;
    color:#103989;
    font-weight: bold;
}

.noBets{
    text-align: center;
}

.mainPhoto{
    position: relative;
    cursor: pointer;
}

.mainPhoto img{
    width: 100%;
}

.zoomPhoto{
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}


.zoomPhotoContent{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.zoomPhoto img{
    position: relative;
    width: 60%;
}

.priceBoxInfo{
    display: flex;
}

.endDateBox{
    padding: 0 15px;
}

.endDateBox h3{
    margin-bottom: 10px;
}

.greyText{
    color: #292527;
    opacity: 0.7;
}

.auctionEnd{
    font-size: 18px;
    color: #103989;
    border-radius: 8px;
    background-color: red;
    height: 100%;
    padding: 10px 35px;
}

.betPlaceInput{
    margin-bottom: 20px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    padding: 8px 12px;
    font-size: 16px;
    padding-right: 0px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 12px;
}

.yourBet{
    margin-top: 20px;
    display: inline;
}

.lastBet{
    color: #292527;
    opacity: 0.7;
}

.placeBetButton{
    align-self: center;
    height: 40px;
    width: 70%;
    padding: 9px 15px 10px;
    background-color: #103989;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius:20px ;
    white-space: nowrap;
}

.map{
    width: 400px;
    height: 400px;
}

.buyModal{
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-size: 14px;
}

.buyModal input{
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    padding: 8px 12px;
    font-size: 16px;
}

.buyModal label{
    margin-top: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}

.buyModal h1{
    margin-bottom: 15px;
}

.buyModalContent{

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    background-color: white;
    padding: 40px;
}

.buyModalAccept{
    margin-top: 20px;
    background-color: #103989;
    color: white;
    border-radius: 20px;
    height: 40px;
    padding: 10px 15px;
}

.buyModalCancel{
    margin-top: 20px;
    color:#6b6e74;
    border-radius: 20px;
    border: solid 1px #dadbdc;
    height: 40px;
    padding: 10px 15px;
    background-color: white;
    margin-right: 20px;
}

.buyModalButtons{
    display: flex;
}

.decriptionBox p{
    word-break: break-word;
}

@media (max-width: 858px){
    .Lot{
        font-size: 0.8em;
    }

    .content, .leftCol, .rightCol{
        width: 100%;
    }

    .leftCol, .rightCol{
        padding: 20px 30px;
        box-sizing: border-box;
    }

    .lotContent{
        flex-direction: column;
        align-items: center;
    }

    .leftArr, .rightArr, .otherPhotos{
        display: none;
    }

    .leftCol{
        display: flex;
        justify-content: center;
        display: none;
    }
    .priceBox, .infoBox{
        flex-direction: column;
    }
    .priceBoxButtons button{
        width: 70%;
        margin: 20px 0;
    }

    .priceBoxInfo{
        display: flex;
        justify-content: space-between;
    }

}