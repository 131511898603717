.Notifications h1{
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 10px 0 30px;
}

.Notifications{
    padding: 0 10%;
    margin-top: 30px;
}

.notificationsContent{
    border-radius: 8px;
    background-color: white;
    width: 100%;
    padding: 10px 40px;
    box-sizing: border-box;
}

.notifItem{
    display: flex;
    border-bottom: 1px solid #e7e7e7;
    padding: 10px 0;
    font-size: 14px;
    color: #292527;
    justify-content: space-between;
}

.notifItem:last-child{
    border-bottom: none;
}

.notifItem p{
    margin-right: 10px;
}

.notifItem a{
    margin-right: 10px;
    color: #103989;
}

.notifLeft{
    display: flex;
}

.notifBox h3{
    text-align: center;
    color: #949293;
    font-size: 16px;
}

.itemTime{
    color: #949293;
}

@media (max-width: 858px){
    .Notifications{
        padding: 0 5%;
    }
}