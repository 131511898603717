body{
    background-color: #f2f2f2;
}
.topBar{
    background-color: white;
}
.title{
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    padding: 30px 0;
}
.list{
    padding: 0 10%;
    display: flex;

}

.settlement{
    flex-direction: row-reverse;
    height: 40px;
    box-sizing: border-box;
}

.active div{
    background-color: #f2f2f2;
    border-radius: 8px;
    color:#103989;
}

.active .count{
    background-color:#abb3d0;
    border-radius: 8px;
    color:white;
}
.content{
    padding: 0 10%;
}
.listItem{
    display: flex;
    padding: 15px;
    align-items: center;
    color: #6b6e74;
    
}
.count{
    margin-left: 5px;
    font-size: 12px;
    padding: 4px;
    background-color:#abb3d0;
    color:white;
    border-radius: 11px;
}

.filter{
    padding:0 10%;
}

.filterBottom{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    color:#6b6e74;
    padding-bottom: 20px;
}
.filterBottomLeft{
    display: flex;
}
.resetBtn{
    font-size: 14px;
    padding: 7px 16px;
    white-space: nowrap;
    margin-left: 40px;
    text-decoration-line: underline;
    color: #103989;
    font-weight: bold;
    align-self: flex-end;
}

.filterBox{
    border: 1px solid #ccc
}

.filterBox p{
    margin-right: 5px;
}

.filterBox{
    position: relative;
    white-space: nowrap;
    justify-content: space-between;
}

.filterBoxContent{
    display: flex;
    align-items: center;
    user-select: none;
}

.filterDropdown{
    padding: 12px 15px 18px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    background-color: white;
    top:50px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
}

.filterItem{
    flex-grow: 3;
    padding-right: 15px;
}

.filterItemSearch{
    flex-grow: 3;
}

.filterInput{
    position: relative;
}
.filterInput input{
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    background-color: #f2f2f2;
    border-radius: 4px;
    border: solid 1px #dadbdc;
    font-size: 16px;
    padding-left: 40px;
}

.filterInput i{
    position: absolute;
    z-index: 40;
    bottom: 9px;
    left: 10px;
    font-size: 24px;
    color:#6b6e74;

}

.filtersButton{
    display: none;
}

.mobileFilterBut{
    display: none;
}

.sideMenuNotActive{
    display: none;
}

#filterCheck{
    display: none;
}

.sideMenu{
    display: none;
}

.notFound{
    text-align: center;
    margin-top: 50px;
    font-weight: normal;
}

.sliderFilter input{
    font-size: 16;
    width: 100px;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px #dadbdc;
}

.sliderInputs{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.sliderLine {
    background-color:#6b6e74;
    opacity: 60%;
    height: 2px;
    margin: 0 5px;
    width: 15px;
}

.filterBottom label{
    display: block;
    margin-bottom: 10px;
    color: #292527;
}

@media (max-width: 990px){

 
    .filterBottom{
        display: none;
    }

    .filtersButton{
        display:flex;
        padding: 8px 12px;
        border-radius: 4px;
        border: solid 1px #dadbdc;
        cursor: pointer;
        margin-bottom: 20px;
        width: fit-content;
        white-space: nowrap;
        color: #4f4f4f;
        min-width: fit-content;
        text-align: center;
    }

    .filtersButton i{
        padding-top: 3px;
        padding-left: 5px;
    }


    .sideMenu{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 80px 5% 80px 5%;
        padding-right: 5%;
        transition: all .5s;
        background-color:white;
        width: 100vw;
        height: 100vh;
        z-index: 90;
        top: 0px;
        position: fixed;
        font-size: 18px;
    }

    .sideMenu label{
        margin-top: 10px;
    }

    .sideMenuHeader i{
        font-size: 35px;
        color: #4f4f4f;
    }

    .sideMenuHeader p{
        font-size: 30px;
        margin-bottom: 20px;
    }

    .sideMenuHeader{
        display: flex;
        justify-content: space-between;
    }

    .filterInput input{
        width: 100%;
    }

    .clearFiltersButton{
        background-color: white;
        color: #4f4f4f;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        padding: 0 5%;
        white-space: nowrap;
    }
    
    .setFiltersButton{
        background-color: #103989;
        color: white;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        height: 40px;
        border-radius: 20px;
        width: 50%;
    }

    .filterButBox{
        display: flex;
        justify-content: space-between;
    }

    .datePickerMobile{
        width: 100%;
    }

    .datePickerMobile label{
        display: block;
    }

    .datePickerMobile input{
        display: block;
        width: 230%
    }
}



@media (max-width: 858px){
}