.AddLotRedesign{
    padding: 50px 10%;
}

.AddLotRedesign input:disabled{
    background-color: #00000008;
}

.content{
    background-color: white;
    padding: 30px;
    border-radius: 4px;
}

.title{
    font-family: "Open Sans";
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 30px;
}

.subtitle{
    font-family: "Open Sans";
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 30px;
}


.inputItem{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;
}

.inputItem input{
    min-width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: solid 1px #dadbdc;
    background-color: inherit;
    font-size: 16px;
    padding: 8px 16px;
    color: #6b6e74;
    padding-right: 30px;

}

.inputItem label{
    color: #292527;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 20px;
}
.inputItem textarea{
    height: 100px;
    min-width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(218, 219, 220);
    background-color: inherit;
    font-size: 16px;
    padding: 8px 30px 8px 16px;
    resize: none;
    color: rgb(107, 110, 116);
}


.itemRow{
    display: flex;
}

.short{
    width: 50%;
}

.itemRowTriple{
    display: flex;
    justify-content: space-between;
}

.itemRowTriple .inputItem{
    width: 30%;
}

.gap{
    padding: 30px 0;
}

hr{
    margin: 50px 0;
}

.line{
    width: 100%;
    height: 1px;
    background-color: #daece1;
    margin: 40px 0 20px;
}

.left{
    width: 50%;
}



.blue{
    color: #103989;
}

.removeAccountBtn{

}

.removeAccountBtn{
    width: 25%;
    align-self: center;
    height: 40px;
    margin-top: 20px;
    display: flex;
    border: 1px solid #ea1a28;
    border-radius: 4px;
    color: #ea1a28;
    align-items: center;
    cursor: pointer;
}

.removeAccountBtn p{
    flex-grow: 1;
    text-align: center;
    font-weight: 600;
}

.removeAccountBtnPlus {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 13px;
    font-size: 30px;
    color: #ea1a28;
    border-right: 1px solid #ea1a28;
}

.blueBtn{
    border: 1px solid #103989;
    color: #103989;
}

.blueBtn .removeAccountBtnPlus {
    border-right: 1px solid #103989;
    color: #103989;
}
    /*#057E36*/

.saveButton{
    border:1px solid #103989;
    height: 40px;
    background-color: white;
    color: #103989;
    font-weight: bold;
    width: 25%;
    border-radius: 4px;
    margin-left: 50%;
    transform: translate(-50%,0);
    margin-top: 30px;
}

.successModal{
    padding: 30px;
    text-align: center;
}

.successModal button{
    background-color: #103989;
    color: white;
    border-radius: 4px;
    font-weight: 600;
    height: 40px;
    padding: 0 20px;
    margin-top: 20px;
}